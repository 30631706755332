import React from 'react'
import {connect, useDispatch} from "react-redux";
import Translate from "../helpers/Translate";
import Select, {components} from 'react-select';
import PaymentAction from "../stores/payment/PaymentAction";


const mapStateToProps = (state) => ({
    ...state,
    ...state.app,
    paymentMethods: state.payment.paymentMethods.methods,
    usePaymentMethod: state.payment.paymentMethods.useMethod,
    paymentProvider: state.payment.paymentMethods.provider,
    customerPaymentData: state.payment.paymentCustomerData,
    isTypeRecurring: state.rental.data?.items?.is_recurring ?? false,
    language: state.language

});


const Option = (props) => {
    return (
        <div>
            <components.Option {...props}  />
        </div>
    );
};

const PaymentOptions = (props) => {

    const dispatch = useDispatch()

    const handlePaymentMethodChange = (selectedOption) => {
        dispatch(PaymentAction.setPaymentMethod(selectedOption.code))
    };

    return (
        <>
            <div className="c-order-overview__box">
                <header>
                    <h3> {Translate('Betaalmethode', props.language.active)}</h3>
                </header>
                <div className="c-order-overview__container-payment-methods">
                    <div className="c-order-overview__selection-payment-methods">
                        <Select
                            placeholder={Translate('Selecteer betaalmethode', props.language.active)}
                            options={props.paymentMethods ?? []}
                            components={{Option: Option}}
                            onChange={handlePaymentMethodChange}
                            value={props.paymentMethods?.find(m => m.code === props.usePaymentMethod)}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    fontSize: '1.6rem',
                                    color: '#000',
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    fontSize: '1.6rem',
                                    backgroundColor: state.isSelected ? "white" : "white",
                                    color: '#000',
                                }),
                            }}
                        />
                    </div>

                    {props.paymentProvider === 'pay' && props.usePaymentMethod?.toLowerCase() === 'incasso' &&
                        <>
                            <div className="c-order-overview__customer-payment-info">
                                <h5>{Translate('Vul a.u.b. uw gegevens in', props.language.active)}</h5>

                                {!props.isTypeRecurring &&
                                    <span> {Translate('Naam', props.language.active)} </span>
                                }

                                {props.isTypeRecurring &&
                                    <span> {Translate('Naam rekeninghouder', props.language.active)} </span>
                                }

                                <label htmlFor="name">
                                    <input
                                        type="text"
                                        id="name"
                                        name={'type'}
                                        onChange={(e) => {
                                            dispatch(PaymentAction.setPaymentCustomerData({name: e.target.value}))
                                        }}
                                        value={props.customerPaymentData.name}
                                        required
                                    />
                                </label>

                                {!props.isTypeRecurring &&
                                    <span> {Translate('Rekeningnummer', props.language.active)} </span>
                                }

                                {props.isTypeRecurring &&
                                    <span> {Translate('IBAN Nummer', props.language.active)} </span>
                                }

                                <label htmlFor="account_number">
                                    <input
                                        type="text"
                                        id="account_number"
                                        name={'type'}
                                        onChange={(e) => {
                                            let target = e.target, position = target.selectionEnd,
                                                length = target.value.length;
                                            e.target.value = target.value.toUpperCase().replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
                                            e.target.selectionEnd = position += ((target.value.charAt(position - 1) === ' ' && target.value.charAt(length - 1) === ' ' && length !== target.value.length) ? 1 : 0);
                                            dispatch(PaymentAction.setPaymentCustomerData({accountNumber: e.target.value}))
                                        }}
                                        required
                                    />
                                </label>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )

}

export default connect(mapStateToProps)(PaymentOptions);
