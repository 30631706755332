import ActionUtility from '../../helpers/ActionUtility'
import RentalEffect from "./RentalEffect";
import ApiEnum from "../../constants/ApiEnum";

export default class RentalAction {
    static API_GET_DETAIL = 'RentalAction.API_GET_DETAIL';

    static apiGetDetail(uuid) {
        return async (dispatch, getState) => {
            const language = getState().language.active

            const values = {
                fetchUrl: ApiEnum.ApiClient + '/rental/detail?uuid=' + uuid + '&language=' + language,
                accessToken: getState().authentication.accessToken
            }

            await ActionUtility.createThunkEffect(dispatch, RentalAction.API_GET_DETAIL, RentalEffect.apiGetDetail, values);
        };
    }

    static pay() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalAction.PAY);
        };
    }

    static status() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalAction.STATUS);
        };
    }

}