import fetchHelper from "../../helpers/fetchHelper";

export default class PaymentEffect {

    static async apiGet(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response
        } else return null

    }

    static async apiStatus(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response.items.transactionData.status
        } else return null

    }


    static async apiPay(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            if (response.status.code === 200) {

                if (response.items.transactionData.paymentMethodCode?.toLowerCase() === 'incasso' && values.recurring_enabled) {
                    window.location = response.items.transactionData.redirectUrl
                    return
                }

                window.location = response.items.transactionData['_links'].checkout.href

            } else {

                return {
                    message: response.status.message
                }

            }
        } else return null

    }

    static async requestPaymentMethods(values, dispatch) {
        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response?.items ?? []

        } else return null

    }

    static async setPaymentMethod(values) {
        return values
    }

    static async setPaymentCustomerData(values) {
        return values
    }


}

