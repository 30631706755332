import ActionUtility from "../../helpers/ActionUtility";
import AuthenticationEffect from "./AuthenticationEffect";
import ApiEnum from "../../constants/ApiEnum";

export default class AuthenticationAction {

    static REQUEST_ACCESS_TOKEN = 'AuthenticationAction.REQUEST_ACCESS_TOKEN';

    static LOGIN = 'AuthenticationAction.LOGIN';
    static LOGOUT = 'AuthenticationAction.LOGOUT';

    static GET_USER_BY_PIN = 'AuthenticationAction.GET_USER_BY_PIN';
    static TIME_OUT = 'AuthenticationAction.TIME_OUT';
    static UNSET_USER = 'AuthenticationAction.UNSET_USER';


    static requestAccessToken() {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AuthenticationAction.REQUEST_ACCESS_TOKEN, AuthenticationEffect.requestAccessToken);
        }

    }

    static login(params) {

        return async (dispatch, getState) => {


            const values = {
                fetchUrl: ApiEnum.Api + '/user/login',
                method: 'post',
                accessToken: getState().authentication.accessToken,
                slug: getState().environment.slug,
                params: {
                    ...params,
                }
            }


            await ActionUtility.createThunkEffect(dispatch, AuthenticationAction.LOGIN, AuthenticationEffect.login, values);
        }

    }

    static logout() {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AuthenticationAction.LOGOUT, AuthenticationEffect.logout);
        }

    }

    static unsetUser() {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AuthenticationAction.UNSET_USER, AuthenticationEffect.unsetUser);
        }

    }

    static getUserByPin(pin) {

        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/user/pin-code',
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    pin_code: pin,
                    partner_id: getState().environment.data.info.partner_id
                }
            }

            await ActionUtility.createThunkEffect(dispatch, AuthenticationAction.GET_USER_BY_PIN, AuthenticationEffect.getUserByPin, values);
        }

    }

    static timeOut() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AuthenticationAction.TIME_OUT, AuthenticationEffect.timeOut);
        }
    }

}
