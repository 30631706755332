import PaymentAction from "./PaymentAction";

export default class PaymentReducer {

    static initialState = {
        data: null,
        pay: null,
        status: null,
        processing: false,
        paymentMethods: {
            loading: false,
            provider: '',
            methods: [],
            useMethod: ''
        },
        paymentCustomerData: {
            name: '',
            accountNumber: '',
        },
    };

    static reducer(state = PaymentReducer.initialState, action) {
        switch (action.type) {

            case PaymentAction.API_GET + "_FINISHED":
                return {
                    ...state,
                    data: action.payload
                }

            case PaymentAction.API_PAY:
                return {
                    ...state,
                    processing: true
                }

            case PaymentAction.API_PAY + "_FINISHED":
                return {
                    ...state,
                    pay: action.payload,
                    processing: false
                }

            case PaymentAction.API_STATUS + "_FINISHED":
                return {
                    ...state,
                    status: action.payload
                }

            case PaymentAction.REQUEST_PAYMENT_METHODS:
                return {
                    ...state,
                    paymentMethods: {
                        ...state.paymentMethods,
                        methods: []
                    },
                }

            case PaymentAction.REQUEST_PAYMENT_METHODS + '_FINISHED':
                return {
                    ...state,
                    paymentMethods: {
                        ...state.paymentMethods,
                        provider: action.payload?.provider ?? '',
                        methods: action.payload?.methods ?? []
                    },
                }

            case PaymentAction.SET_PAYMENT_METHODS:
                return {
                    ...state,
                    paymentMethods: {
                        ...state.paymentMethods,
                        useMethod: ''                    },
                }

            case PaymentAction.SET_PAYMENT_METHODS + '_FINISHED':
                return {
                    ...state,
                    paymentMethods: {
                        ...state.paymentMethods,
                        useMethod: action.payload
                    },
                }
            case PaymentAction.SET_PAYMENT_CUSTOMER_DATA + '_FINISHED':
                return {
                    ...state,
                    paymentCustomerData: {
                        name: action.payload.name ?? state.paymentCustomerData.name,
                        accountNumber: action.payload.accountNumber ?? state.paymentCustomerData.accountNumber,
                    },
                }

            default:
                return state;
        }
    }
}

