import ActionUtility from '../../helpers/ActionUtility'
import PaymentEffect from "./PaymentEffect";
import ApiEnum from "../../constants/ApiEnum";

export default class PaymentAction {
    static API_GET = 'TransactionAction.API_GET';
    static API_PAY = 'TransactionAction.API_PAY';
    static API_STATUS = 'TransactionAction.API_STATUS';
    static REQUEST_PAYMENT_METHODS = 'TransactionAction.REQUEST_PAYMENT_METHODS';
    static SET_PAYMENT_METHODS = 'TransactionAction.SET_PAYMENT_METHODS';
    static SET_PAYMENT_CUSTOMER_DATA = 'TransactionAction.SET_PAYMENT_CUSTOMER_DATA';

    static apiGet(uuid) {
        return async (dispatch, getState) => {

            const language = getState().language.active

            const values = {
                fetchUrl: ApiEnum.ApiClientPaymentGet + '?rental=' + uuid + '&language=' + language,
                accessToken: getState().authentication.accessToken
            }

            await ActionUtility.createThunkEffect(dispatch, PaymentAction.API_GET, PaymentEffect.apiGet, values);
        };
    }

    static pay(deposit) {
        return async (dispatch, getState) => {

            const language = getState().language.active
            const uuid = getState().rental.data.items.rental.uuid
            const paymentMethod = getState().payment.paymentMethods.useMethod
            const paymentProvider = getState().payment.paymentMethods.provider
            const paymentCustomerData = getState().payment.paymentCustomerData

            const values = {
                fetchUrl: ApiEnum.ApiClientPaymentPay + '?rental=' + uuid + '&language=' + language + '&type=' + paymentProvider,
                accessToken: getState().authentication.accessToken,
                payment_customer_data: paymentCustomerData,
                recurring_enabled: !!getState().rental.data.items.is_recurring,
                method: 'post',
                params: {
                    redirect_url: window.location.origin + window.location.pathname + '/processing',
                    payment_method: paymentMethod,
                    payment_customer_data: paymentCustomerData,
                }
            }

            await ActionUtility.createThunkEffect(dispatch, PaymentAction.API_PAY, PaymentEffect.apiPay, values);

        };
    }

    static apiStatus(uuid, deposit) {
        return async (dispatch, getState) => {

            const language = getState().language.active

            const values = {
                fetchUrl: ApiEnum.ApiClientPaymentStatus + '?rental=' + uuid + '&language=' + language,
                accessToken: getState().authentication.accessToken
            }

            await ActionUtility.createThunkEffect(dispatch, PaymentAction.API_STATUS, PaymentEffect.apiStatus, values);
        };
    }
    static getPaymentMethods(rentalUuid) {
        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.ApiClientPaymentMethods + '?uuid=' + rentalUuid,
                accessToken: getState().authentication.accessToken,
            }
            await ActionUtility.createThunkEffect(dispatch, PaymentAction.REQUEST_PAYMENT_METHODS, PaymentEffect.requestPaymentMethods, values);
        };
    }

    static setPaymentMethod(values) {
        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, PaymentAction.SET_PAYMENT_METHODS, PaymentEffect.setPaymentMethod, values);
        };
    }
    static setPaymentCustomerData(values) {
        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, PaymentAction.SET_PAYMENT_CUSTOMER_DATA, PaymentEffect.setPaymentCustomerData, values);
        };
    }

}
