const ApiEnum = {
    Api: process.env.REACT_APP_API_URL + '/partner',
    ApiClient: process.env.REACT_APP_API_URL + '/client',
    key: '3c3ab799-89c1-48a0-90cf-9fb17548db01',
    secret: '1442671c-1874-4dda-bf80-943e7be19888',

    ApiAuthConnect: process.env.REACT_APP_API_URL +'/auth/connect',
    ApiPartnerUserLogin: process.env.REACT_APP_API_URL +'/partner/user/login',
    ApiPartnerUserPinCode: process.env.REACT_APP_API_URL +'/partner/user/pin-code',

    ApiClientMutationList: process.env.REACT_APP_API_URL +'/client/mutation/list',
    ApiClientPaymentGet: process.env.REACT_APP_API_URL +'/client/payment/get',
    ApiClientPaymentPay: process.env.REACT_APP_API_URL +'/client/payment/pay',
    ApiClientPaymentStatus: process.env.REACT_APP_API_URL +'/client/payment/status',
    ApiClientRentalDetail: process.env.REACT_APP_API_URL +'/client/rental/detail',
    ApiClientPaymentMethods: process.env.REACT_APP_API_URL +'/client/payment/payment-methods'
}

export default ApiEnum
