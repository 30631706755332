import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './styles/main.scss';
import App from './views/App';
import {createMemoryHistory} from 'history';
import rootStore from './stores/rootStore';
import {Route, BrowserRouter} from 'react-router-dom'


(async (window) => {
    const initialState = {};
    const history = createMemoryHistory();
    const store = rootStore(initialState, history);
    const rootEl = document.getElementById('root');
    const render = (Component, el) => {
        ReactDOM.render(
            <Provider store={store}>

                <BrowserRouter basename={'/'}>

                    <Component  />

                    <Route
                        path={'/:rentalUuid?'}
                        history={history} dispatch={store.dispatch}
                        render={(props) => (
                            <App {...props} />
                        )}
                    />


                </BrowserRouter>

            </Provider>,
            el
        );
    };

    render('div', rootEl);

})(window);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
