import React from 'react'
import {connect} from "react-redux";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import {Redirect} from "react-router";
import PaymentAction from "../stores/payment/PaymentAction";
import Translate from "../helpers/Translate";

const mapStateToProps = (state) => ({
    payment: state.payment,
    paymentStatus: state.payment.data?.items?.transaction?.status,
    rental: state.rental,
    language: state.language,
    error: state.error
});

const Pending = (props) => {

    return (
        <div className={'c-loader c-loader-full'} key={'bicycle-loader'}>

            <div className="content">

                <h3 style={{textAlign: 'center', minWidth: '100%'}}>{Translate('Betaling in verwerking...', props.language.active)}</h3>

                <Loader
                    type="Rings"
                    color={'#272727'}
                    height={100}
                    width={100}

                />

            </div>

        </div>
    )

}

class Processing extends React.Component {

    componentDidMount() {
        this.timeout();
    }

    timeout() {
        const intervalFunc = () => {
            this.props.dispatch(PaymentAction.apiStatus(this.props.rental.data.items.rental.uuid))
        }

        setTimeout(intervalFunc, 3000);
    }

    render() {
        const status = this.props.paymentStatus

        if (!status) {
            return <Pending {...this.props} />
        } else {
            return <Redirect to={'/' + this.props.rental.data.items.rental.uuid + '/result'}/>
        }


    }

}

export default connect(mapStateToProps)(Processing);
